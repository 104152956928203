import React, { Component } from 'react'
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";

import './Swiper.css'
import { FaWhatsapp } from "react-icons/fa";
import Resimone from './images/slider_loft1.jpg'
import Resimtwo from './images/slider_loft2.jpg'
import Resimthree from './images/slider_loft3.jpg'
import Resimfour from './images/slider_loft4.jpg'
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";

import SwiperCore, {
    Autoplay,Pagination,Navigation
  } from 'swiper';
  SwiperCore.use([Autoplay,Pagination,Navigation]);

export default class Header extends Component {
  
    render() {
     
        return (
            <div className=' headertum'>
              
               
                 <Swiper spaceBetween={30} centeredSlides={true} autoplay={{
  "delay": 3000,
  "disableOnInteraction": false
}} pagination={{
  "clickable": true
}} navigation={true} className="mySwiper">
  <SwiperSlide><img src={Resimone}/></SwiperSlide>
  <SwiperSlide><img src={Resimthree}/></SwiperSlide>
  <SwiperSlide><img src={Resimtwo}/></SwiperSlide>
  <SwiperSlide><img src={Resimfour}/></SwiperSlide>
  </Swiper>
  
  <div className='esa'><a href="https://wa.me/9005326940079?text="><h1 className='whatsaapicon'><FaWhatsapp /></h1></a>
</div>


            </div>
        )
    }
}

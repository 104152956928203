import React, { Component } from 'react';
import{Navbar,Nav,NavDropdown,Form,FormControl,Button,Container,Offcanvas} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css'
import Resimiki from './images/Group 209.png'
import Resimbir from './images/Group 254.png'
import Arabic from './images/arabicbayrak.png'
import İranbayrak from './images/iranbayrak.png'
import Rusbayrak from './images/rusbayrak.png'
import Ingilterekbayrak from './images/ingiltere.jpg'
import { Link } from 'react-router-dom'
import Popupgizlilik from '../Anasayfa/poppup/Popupgizlilik';
import { FaFacebook } from "react-icons/fa";
import { FaInstagram  } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
export default class Navbermobil extends Component {
  render() {
    return <div className='navmabil'>
  <Navbar bg="light" expand={false}>
  <Container fluid>
    <Navbar.Brand href="#"></Navbar.Brand>
    <Navbar.Toggle aria-controls="offcanvasNavbar" />
    <Navbar.Offcanvas
      id="offcanvasNavbar"
      aria-labelledby="offcanvasNavbarLabel"
      placement="end"
    >
      <Offcanvas.Header closeButton>
      <Offcanvas.Title id="offcanvasNavbarLabel"></Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Nav className="justify-content-end flex-grow-1 pe-3">
          <h1><b>0532 694 00 79</b></h1><br/>
          <Nav.Link><Link to="/"style={{ textDecoration: 'none' }}><h1>Anasayfa</h1></Link></Nav.Link>
          <Nav.Link ><Link to="/hakkimizda"style={{ textDecoration: 'none' }}><h1>Hakkımızda</h1></Link></Nav.Link>
          <Nav.Link ><Link to="/loftvalentine"style={{ textDecoration: 'none' }}><h1>Loft Valentine</h1></Link></Nav.Link>
          <Nav.Link><Link to="/katplani"style={{ textDecoration: 'none' }}><h1>Kat Planı</h1></Link></Nav.Link>
          <Nav.Link><Link to="/gorsel"style={{ textDecoration: 'none' }}><h1>Görseller</h1></Link></Nav.Link>
          <a href="https://tr.blog.ruzproje.com/"style={{ textDecoration: 'none' }}><h1>Ruz Proje Tasarım</h1></a>
          <Nav.Link><Link to="/iletisim"style={{ textDecoration: 'none' }}><h1>İletişim</h1></Link></Nav.Link>
          
        </Nav>
        
       <center><Popupgizlilik/></center> 
        <div className='container'>
        <div className='row'>
        
       
        </div></div>
        <div className='container dilsecenek'>
          <div className='row'>
          <div className='col-3'>       <Nav.Link><Link to="/anasayfa/en"style={{ textDecoration: 'none' }}><img className='col-md-12 resimbir' src={Ingilterekbayrak}/></Link></Nav.Link></div>
<div className='col-3'>     <Nav.Link><Link to="/anasayfaarabic"style={{ textDecoration: 'none' }}><img className='col-md-12 resimbir' src={Arabic}/></Link></Nav.Link></div>   
<div className='col-3'>       <Nav.Link><Link to="/anasayfa/rus"style={{ textDecoration: 'none' }}><img className='col-md-12 resimbir' src={Rusbayrak}/></Link></Nav.Link></div>
<div className='col-3'>       <Nav.Link><Link to="/anasayfa/fas"style={{ textDecoration: 'none' }}><img className='col-md-12 resimbir' src={İranbayrak}/></Link></Nav.Link></div>

</div>
        </div>
<div className='container mobilsosyalicons'>
  <div className='row'>
 
       <div className='col-4'> <a href="https://www.facebook.com/profile.php?id=100069853207866"><h1 className='sosysalmedyaiconfacebookk'><FaFacebook /></h1></a></div> 
       <div className='col-4'><a href="https://www.loftvalentine.com/sosyalmedya/?page_id=8"><h1 className='sossyalmedyaiconinstgramm'><FaInstagram /></h1></a></div> 
       <div className='col-4'><a href="https://www.loftvalentine.com/sosyalmedya/?page_id=13"><h1 className='sossyalmedyaiconyoutubee'><FaYoutube /></h1></a></div> 
</div>
</div>
        
      </Offcanvas.Body>
    </Navbar.Offcanvas>
  </Container>
</Navbar>
    </div>;
  }
}


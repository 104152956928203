import React from 'react'
import './styles.css'
import { BsWhatsapp } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";

import Popuplinkmail from '../component/Anasayfa/poppup/Popuplinkmail';
import ReactWhatsapp from 'react-whatsapp';
export default function Linkler() {
  <ReactWhatsapp number="0-531-913-4063" message="Hello World!!!" />
  return (
    <div className='linklere'>
        <h3><a href="https://wa.me/9005326940079?text=" target="_blank"><BsWhatsapp/></a></h3>
        <h3 className='arkah3'> <a href="Tel:02129092828" target="_blank"><IoIosCall/></a></h3>
        <h3><Popuplinkmail/></h3>
        <div className=''>
        <div className='sosyalmedya'> <a href="https://www.facebook.com/profile.php?id=100069853207866"><h2 className='sosyalmedyaiconfacebook'><FaFacebook /></h2></a>
<a href="https://www.loftvalentine.com/sosyalmedya/?page_id=8"><h2 className='sosyalmedyaiconinstgram'><FaInstagram /></h2></a>
<a href="https://www.loftvalentine.com/sosyalmedya/?page_id=13"><h2 className='sosyalmedyaiconyoutube'><FaYoutube /></h2></a>
</div>
        </div>
    </div>
  )
}

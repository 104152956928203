import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './style.css'
import Poppupp from '../poppup/Poppupp';
import Bilgiform from './Bilgiform';
import { AiFillHome } from "react-icons/ai";
import 'reactjs-popup/dist/index.css';
import Resimone from './images/Group 254.png'
import Popupgizlilik from '../poppup/Popupgizlilik';



export default class Navbarjs extends Component {
    render() {
        return (
           
          <div className='navbarr'>
             
          <div className='Gizlilik'><Popupgizlilik/></div>
          <div className='logolar'><img src={Resimone}/></div>

      
          <div className='yazi'>
          <Link to='/anasayfa/fas'style={{textDecoration: 'none',  }}><h5><AiFillHome/></h5> </Link>
          <Link to='/hakkimizda/fas'style={{ textDecoration: 'none' }}><h5>درباره ما</h5></Link>
          <Link to='/loftvalentine/fas'style={{ textDecoration: 'none' }}><h5>Loft Valentine</h5></Link>
          <Link to='/katplani/fars'style={{ textDecoration: 'none' }}><h5>پلان طبقات</h5></Link>
          <Link to='/gorsel/fars'style={{ textDecoration: 'none' }}><h5>تصاویر</h5></Link>
          <a href='https://blog.ruzproje.com/'style={{ textDecoration: 'none' }}><h5>طراحی پروژه روز </h5></a>
          <a href='https://www.loftvalentine.com/sosyalmedya/?page_id=8'style={{ textDecoration: 'none' }}><h5>Instagram </h5></a>
          <a href='https://www.facebook.com/profile.php?id=100069853207866'style={{ textDecoration: 'none' }}><h5>Facebook </h5></a>
          <a href='https://www.loftvalentine.com/sosyalmedya/?page_id=13'style={{ textDecoration: 'none' }}><h5>Youtube </h5></a>
          <Link to='/iletisim/fars'style={{ textDecoration: 'none' }}><h5>ارتباط</h5></Link>
        
          </div>
      </div>
        )
    }
}


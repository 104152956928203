import React, { Component } from 'react';
import{Navbar,Nav,NavDropdown,Form,FormControl,Button,Container,Offcanvas} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css'
import Turkbayrak from './images/turkbayrak.png'
import Enbayrak from './images/ingiltere.jpg'
import Resimbir from './images/Group 254.png'
import Arabic from './images/arabicbayrak.png'
import İranbayrak from './images/iranbayrak.png'
import { Link } from 'react-router-dom'
import Popupgizlilik from '../Anasayfa/poppup/Popupgizlilik';
import { FaFacebook } from "react-icons/fa";
import { FaInstagram  } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
export default class Navbermobil extends Component {
  render() {
    return <div className='navmabil'>
  <Navbar bg="light" expand={false}>
  <Container fluid>
    <Navbar.Brand href="#"></Navbar.Brand>
    <Navbar.Toggle aria-controls="offcanvasNavbar" />
    <Navbar.Offcanvas
      id="offcanvasNavbar"
      aria-labelledby="offcanvasNavbarLabel"
      placement="end"
    >
      <Offcanvas.Header closeButton>
      <Offcanvas.Title id="offcanvasNavbarLabel"></Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Nav className="justify-content-end flex-grow-1 pe-3">
          <h1><b>0532 694 00 79</b></h1><br/>
          <Nav.Link><Link to="/anasayfa/rus"style={{ textDecoration: 'none' }}><h1>домашняя страница</h1></Link></Nav.Link>
          <Nav.Link ><Link to="/hakkimizda/rus"style={{ textDecoration: 'none' }}><h1>о нас</h1></Link></Nav.Link>
          <Nav.Link ><Link to="/Loftvalentine/rus"style={{ textDecoration: 'none' }}><h1>Лофтвалентин</h1></Link></Nav.Link>
          <Nav.Link><Link to="/katpalani/rus"style={{ textDecoration: 'none' }}><h1>Планировка этажей</h1></Link></Nav.Link>
          <Nav.Link><Link to="/gorsel/rus"style={{ textDecoration: 'none' }}><h1>Изображений</h1></Link></Nav.Link>
          <Nav.Link><Link to="/iletisim/rus"style={{ textDecoration: 'none' }}><h1>Коммуникация</h1></Link></Nav.Link>
          
        </Nav>
        
       <center><Popupgizlilik/></center> 
        <div className='container'>
        <div className='row'>
          
        
        </div></div>
        <div className='container dilsecenek'>
          <div className='row'>
          <div className='col-3'>       <Nav.Link><Link to="/"style={{ textDecoration: 'none' }}><img className='col-md-12 resimbir' src={Turkbayrak}/></Link></Nav.Link></div>
<div className='col-3'>     <Nav.Link><Link to="/anasayfa/en"style={{ textDecoration: 'none' }}><img className='col-md-12 resimbir' src={Enbayrak }/></Link></Nav.Link></div>   
<div className='col-3'>       <Nav.Link><Link to="/anasayfaarabic"style={{ textDecoration: 'none' }}><img className='col-md-12 resimbir' src={Arabic}/></Link></Nav.Link></div>
<div className='col-3'>       <Nav.Link><Link to="/anasayfa/fas"style={{ textDecoration: 'none' }}><img className='col-md-12 resimbir' src={İranbayrak}/></Link></Nav.Link></div>

</div>
        </div>  <div className='container mobilsosyalicons'>
  <div className='row'>
       
       <div className='col-4'> <a href="https://www.facebook.com/profile.php?id=100069853207866"><h1 className='sosysalmedyaiconfacebookk'><FaFacebook /></h1></a></div> 
       <div className='col-4'><a href="https://www.loftvalentine.com/sosyalmedya/?page_id=8"><h1 className='sossyalmedyaiconinstgramm'><FaInstagram /></h1></a></div> 
       <div className='col-4'><a href="https://www.loftvalentine.com/sosyalmedya/?page_id=13"><h1 className='sossyalmedyaiconyoutubee'><FaYoutube /></h1></a></div> 
</div>
</div>
        
        
      </Offcanvas.Body>
    </Navbar.Offcanvas>
  </Container>
</Navbar>
    </div>;
  }
}

